/* Colour Palette */
:root {
  --green: #012920;
  --cream: #d8c8b7;
  --orange: #ba481b;
  --white: #ffffff;
  --brown: #281c14;
}

html,
body {
  margin: 0;
  height: 100%;
}

button {
  background-color: unset;
  border: unset;
  padding: unset;
}

#ms_page {
  height: 100%;
}

.flex {
  display: flex;
}

.row {
  flex-direction: row;
}

.col {
  flex-direction: column;
}

.center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.start {
  justify-content: start;
}

.end {
  justify-content: end;
}

.between {
  justify-content: space-between;
}

.gap,
.gap-m {
  gap: 1rem;
}

.gap-s {
  gap: 0.5rem;
}

.gap-l {
  gap: 2rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.p-4 {
  padding: 4rem;
}

.m-auto {
  margin: auto;
}

.h-full {
  height: 100%;
}

.siteContainer {
  height: 100%;
}

.pageContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

@media screen and (max-width: 700px) {
  .pageContainer {
    padding: 30% 10% 10%;
    gap: 2rem;
    align-items: start;
  }

  .logoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.metaContainer {
  position: absolute;
  top: 10%;
  opacity: 0;
}

.metaContainer h1 {
  opacity: 0;
}

.metaContainer h2 {
  opacity: 0;
}

.textContainer {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: white;
}

.textContainer h3 {
  font-weight: 200;
}

.ms_nav_container {
  background-color: black;
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
}

.ms_nav--aside {
  color: white;
}

.ms_nav--aside > p {
  opacity: 0;
  animation: appear 2s forwards ease-out;
}

.ms_nav--container {
  list-style: none;
  font-size: 2rem;
  color: white;
  opacity: 0;
  overflow: hidden;
  margin: unset;
  animation: appear 2s forwards ease-out;
  animation-delay: 0.25s;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ms_nav--menu-item > a {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  gap: 1rem;
  color: white;
}

.ms_nav--bean {
  content: "";
  border: 0.1rem solid lightgray;
  border-top-left-radius: 28%;
  border-top-right-radius: 70%;
  border-bottom-right-radius: 20%;
  border-bottom-left-radius: 70%;
  background-color: white;
  display: inline-block;
  height: 1rem;
  width: 1rem;
  overflow: hidden;
}

.ms_nav--bean::before,
.ms_nav--bean::after {
  content: "";
  display: block;
  border-color: #3d2700;
  border-top-width: 0.2rem;
  border-right-width: 0.2rem;
  border-left-width: 0px;
  border-bottom-width: 0px;
  border-style: solid;
  width: 40%;
  position: relative;
}

.ms_nav--bean::before {
  height: 35%;
  top: 12%;
  left: -10%;
}

.ms_nav--bean::after {
  top: 5%;
  height: 40%;
  left: 30%;
}

@media screen and (min-width: 700px) {
  .ms_Btn {
    display: none;
  }
}

.ms_nav--Btn {
  position: relative;
  height: 6vh;
  width: 10vw;
  max-width: 3rem;
  cursor: pointer;
}

.ms_nav--Btn > span {
  background-color: white;
  width: 100%;
  height: 10%;
}

.ms_nav--Btn::after {
  background-color: red;
}

.closedNav {
  z-index: 99;
  right: 3rem;
  top: 3rem;
}

.slider {
  height: 100vh;
  flex-grow: 1;
  color: var(--cream);
}

.invert {
  color: black;
}

.expanded {
  z-index: 9;
  animation: expand 1s forwards ease-in;
  max-width: 80vw;
}

@keyframes expand {
  from {
    flex-grow: 1;
  }
  to {
    flex-grow: 10;
  }
}

.sliderContainer,
.tileContainer {
  height: 100%;
}

.tileContainer {
  padding: 2rem 4rem 2rem 2rem;
}

.sliderTitleContainer {
  position: relative;
  cursor: default;
}

.sliderContentContainer {
  padding-left: 4rem;
  width: auto;
  max-height: 100%;
  overflow: scroll;
}

@media screen and (max-width: 700px) {
  .sliderContentContainer {
    padding-left: unset;
  }
}

.sliderTitle {
  position: absolute;
  height: 30rem;
  writing-mode: vertical-lr;
  text-orientation: upright;
  left: 0.5rem;
  top: 0.5rem;
  font-size: 1.5rem;
}

.windowContainer {
  position: relative;
}

.windowContainer > img {
  position: absolute;
  height: 8rem;
  right: 0px;
  filter: invert(42%) sepia(20%) saturate(1440%) hue-rotate(353deg) brightness(5) contrast(100%);
}

.windowContainer.inverted > img {
  position: absolute;
  height: 8rem;
  right: 0px;
  filter: invert(84%) sepia(9%) saturate(440%) hue-rotate(350deg) brightness(94%) contrast(98%);
}

.sliderTileOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  cursor: pointer;
}
